<template>
  <v-menu
    bottom
    left
    offset-y
  >
    <template #activator="{ on }">
      <v-btn
        small
        ripple
        icon
        plain
        v-on="on"
      >
        <v-icon>fal fa-ellipsis-v</v-icon>
      </v-btn>
    </template>
    <v-list
      class="py-0"
      dense
    >
      <v-list-item
        v-if="$auth.check({'lab results': ['*', 'view']}) && !$auth.check({'lab results': ['interpret']})"
        :to="`/lab_results/${labResult.uuid}`"
      >
        <v-list-item-action class="mr-4">
          <v-icon
            key="fal fa-file-check fa-fw"
            small
          >
            fal fa-file-magnifying-glass fa-fw
          </v-icon>
        </v-list-item-action>
        <v-list-item-content><v-list-item-title>{{ $t('View') }} {{ $tc('Lab Result') }}</v-list-item-title></v-list-item-content>
      </v-list-item>
      <v-list-item
        v-if="$auth.check({'lab results': ['*', 'interpret']}) && labResult.interpretation && labResult.status != 'quality assurance'"
        :to="{ name:'Review Interpretation', params: { uuid:labResult.uuid } }"
      >
        <v-list-item-action class="mr-4">
          <v-icon
            key="fal fa-file-check fa-fw"
            small
          >
            fal fa-file-check fa-fw
          </v-icon>
        </v-list-item-action>
        <v-list-item-content><v-list-item-title>Review Report</v-list-item-title></v-list-item-content>
      </v-list-item>
      <v-list-item
        v-if="labResult.status == 'needs translation' && $auth.check({'lab results': ['*', 'interpret', 'translate']}) && labResult.interpretation"
        :to="{ name:'Translate Interpretation', params: { uuid:labResult.uuid } }"
      >
        <v-list-item-action class="mr-4">
          <v-icon
            key="fal fa-language fa-fw"
            small
          >
            fal fa-language fa-fw
          </v-icon>
        </v-list-item-action>
        <v-list-item-content><v-list-item-title>Translate Report</v-list-item-title></v-list-item-content>
      </v-list-item>
      <v-list-item
        v-if="$auth.check({'lab results': 'interpret'}) &&
          !['data extraction','do not interpret','partial data','pending approval'].includes(labResult.status) &&
          (!labResult.interpretation || (labResult.interpretation && !labResult.interpretation.approved_at))"
        :to="{ name:'Interpretation', params: { uuid:labResult.uuid } }"
      >
        <v-list-item-action class="mr-4">
          <v-icon
            key="fal fa-file-magnifying-glass fa-fw"
            small
          >
            fal fa-file-magnifying-glass fa-fw
          </v-icon>
        </v-list-item-action>
        <v-list-item-content><v-list-item-title>{{ labResult.status == 'quality assurance' ? 'QA Review' : 'Interpret' }}</v-list-item-title></v-list-item-content>
      </v-list-item>
      <v-list-item
        v-if="!labResult.interpretation && labResult.status === 'data extraction'"
        :to="{ name:'Data Extraction', params: { uuid:labResult.uuid } }"
      >
        <v-list-item-action class="mr-4">
          <v-icon
            key="fal fa-eye-dropper fa-fw"
            small
          >
            fal fa-eye-dropper fa-fw
          </v-icon>
        </v-list-item-action>
        <v-list-item-content><v-list-item-title>Extract Data</v-list-item-title></v-list-item-content>
      </v-list-item>
      <v-list-item
        v-if="(labResult.interpretation?.approved_at || labResult.negative) && $auth.check({ patients: 'phi' })"
        :download="labResult.uuid"
        @click="fetchInterpretationPDF()"
      >
        <v-list-item-action class="mr-4">
          <v-icon
            key="fal fa-file-arrow-down fa-fw"
            small
          >
            fal fa-file-arrow-down fa-fw
          </v-icon>
        </v-list-item-action>
        <v-list-item-content><v-list-item-title>{{ $t('download', { file: $tc('OneChoice Report') }) }}</v-list-item-title></v-list-item-content>
      </v-list-item>
      <v-list-item
        v-if="labResult.interpretation?.approved_at || labResult.negative"
        :download="labResult.uuid"
        @click="fetchInterpretationPDF(true)"
      >
        <v-list-item-action class="mr-4">
          <v-icon
            key="fal fa-file-xmark fa-fw"
            small
          >
            fal fa-file-xmark fa-fw
          </v-icon>
        </v-list-item-action>
        <v-list-item-content><v-list-item-title>{{ $t('download', { file: `${$t('Redacted', { item: $tc('OneChoice Report')})}` } ) }}</v-list-item-title></v-list-item-content>
      </v-list-item>

      <a
        v-if="labResult.one_choice_plus_url"
        :href="labResult.one_choice_plus_url"
        target="_blank"
        style="text-decoration:none"
      >
        <v-list-item
          link
        >
          <v-list-item-action class="mr-4">
            <v-icon
              key="fal fa-arrow-up-right-from-square fa-fw"
              small
            >
              fal fa-arrow-up-right-from-square fa-fw
            </v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              OneChoice Plus
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </a>

      <template v-if="$auth.check({'interpretations': ['*', 'unapprove']}) && labResult.interpretation?.approved_at">
        <v-divider />
        <unnapprove-interpretation
          :key="labResult.uuid"
          :lab-result="labResult"
          @unapproved="$emit('fetch-lab-results')"
        />
      </template>

      <template v-if="$auth.check({'lab results': ['*', 'delete', 'resend', 'add']})">
        <v-divider />
        <v-list-item
          v-if="
            (
              labResult.interpretation?.approved_at ||
              labResult.negative
            ) &&
              $auth.check({'lab results': ['*', 'resend']})
          "
          @click="resendInterpretation()"
        >
          <v-list-item-action class="mr-4">
            <v-icon
              key="fal fa-trash fa-fw"
              small
            >
              fal fa-file-export fa-fw
            </v-icon>
          </v-list-item-action>
          <v-list-item-content><v-list-item-title>Resend Interpretation</v-list-item-title></v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="$auth.user().client && $auth.check({'lab results': ['*', 'add']})"
          @click="$emit('edit-lab-result', labResult.uuid)"
        >
          <v-list-item-action class="mr-4">
            <v-icon
              key="fal fa-file-pen fa-fw"
              small
            >
              fal fa-file-pen fa-fw
            </v-icon>
          </v-list-item-action>
          <v-list-item-content><v-list-item-title>{{ $t('Edit') }} {{ $tc('Lab Result', 1) }}</v-list-item-title></v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="$auth.check({'lab results': ['*', 'delete']})"
          @click="deleteLabResult()"
        >
          <v-list-item-action class="mr-4">
            <v-icon
              key="fal fa-trash fa-fw"
              small
            >
              fal fa-trash fa-fw
            </v-icon>
          </v-list-item-action>
          <v-list-item-content><v-list-item-title>{{ $t('Delete') }}</v-list-item-title></v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
  </v-menu>
</template>
<script>
export default {
  components: {
    UnnapproveInterpretation: () => import('@/components/interpretation/UnapproveInterpretation.vue'),
  },
  props: {
    labResult: {
      type: Object,
      required: true,
    },
  },
  methods: {
    fetchInterpretationPDF (redacted = false) {
      this.axios.get('/lab_results/' + this.labResult.uuid + '/interpretation/pdf', {
        params: {
          output: 'base64',
          redacted: redacted,
          download: true,
        },
      })
        .then((response) => {
          const link = document.createElement('a')
          link.href = 'data:application/pdf;base64,' + response.data.report_content
          link.setAttribute('download', this.labResult.external_id + ' - ' + this.labResult.sample_type + ' - ' + this.labResult.patient.first_name + ' ' + this.labResult.patient.last_name + ' - Arkstone.pdf')
          document.body.appendChild(link)
          link.click()
          link.remove()
        })
        .catch(error => {
          if (error.response?.data?.message) {
          this.$toast.error(error.response.data.message)
        }
        })
    },
    deleteLabResult () {
      this.$dialog.prompt({
        title: 'Delete lab result?',
        text: 'Enter the Result ID again to confirm',
        actions: {
          false: 'Cancel',
          true: {
            text: 'Delete',
            color: 'error',
          },
        },
      })
        .then(confirm => {
          if (confirm === this.labResult.external_id) {
            this.axios
              .delete('lab_results/' + this.labResult.uuid)
              .then(() => {
                this.$emit('fetch-lab-results')
              })
          }
        })
    },
    resendInterpretation () {
      this.$dialog.confirm({
        text: `Resend interpretation for Lab Result ${this.labResult.external_id}?`,
        title: 'Resend Interpretation',
      }).then(res => {
        if (res) {
          this.axios.post(`lab_results/${this.labResult.uuid}/interpretation/resend`)
          .then(() => {
            this.$toast.success(`Interpretation for Lab Result ${this.labResult.external_id} has been resent`)
          })
          .catch(error => {
            if (error.response?.data?.message) {
          this.$toast.error(error.response.data.message)
        }
          })
        }
      })
    },
  },
};
</script>
